import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about" theme={theme}>
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="statistika vodenja prodaje električne energije" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>Zakaj trgovanje z elektriko?</h1>
          <p>
            Leta 2018 smo videli, da ljudje želijo boljše prihanke na področju električne energije. Sam trg se je zelo
            razširil na področju ponudbe in konkurenca je bila ogromna. Z našim naprednim algoritmom smo lahko
            predvideli napoved trga do <strong>80 %</strong> natačno.
          </p>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};

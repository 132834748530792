import React from 'react';
import { Button, Layout, SEO } from 'components/common';
import { Intro, Skills, Contact } from 'components/landing';
import { Wrapper } from 'components/landing/Contact/styles';
import { Link } from 'gatsby';
import { Flex } from 'components/theme/Footer/styles';

export default () => (
  <Layout>
    <SEO />
    <Intro />
    <Skills />
    <Contact />
    <Wrapper style={{ alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          flexDirection: 'column',
          gap: 20,
          justifyContent: 'center',
        }}
      >
        <h4 style={{ fontSize: '20px' }}>Želite sodelovati z nami?</h4>
        <Link to="/zaposlitev">
          <Button style={{ fontSize: '20px' }}>Pridružite se nam</Button>
        </Link>
      </div>
    </Wrapper>
  </Layout>
);

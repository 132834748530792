import styled, { keyframes } from 'styled-components';
import overlayIllustration from 'assets/illustrations/bg.jpg';

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  background-image: url(${overlayIllustration});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const IntroWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 60vh;
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const Details = styled.div`
  margin-top: 50px;
  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: normal;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 1px #333;
    animation-name: ${fadeInUp};
    animation-duration: 1s;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: both;

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

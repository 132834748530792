import React, { useContext } from 'react';
import { Container } from 'components/common';
import contact from 'assets/illustrations/deal.svg';
import { ThemeContext } from 'providers/ThemeProvider';
import { Wrapper, Details, Thumbnail } from './styles';

export const Contact = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Wrapper as={Container} id="consult" style={{ alignItems: 'center' }}>
        <Details theme={theme}>
          <h1>Svetovanje glede trgovanja</h1>
          <p>
            Zaradi izjemne kompleksnosti in zahtevnosti trga smo pridobili edinstvene izkušnje za kakovostno in
            učinkovito trgovanje. Z našim znanjem vam lahko pomagamo pri ustvarjanju novega kapitala.
          </p>
        </Details>
        <Thumbnail>
          <img src={contact} alt="Stopite v stik z nami" />
        </Thumbnail>
      </Wrapper>
      <Wrapper as={Container} id="contact" style={{ alignItems: 'center' }}>
        <Details theme={theme} style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <h1>Stopite v stik z nami</h1>
          <p>ENETRA, transport in prodaja energije d.o.o.</p>
          <p>Dunajska cesta 156 (WTC), Ljubljana, 1000 Ljubljana</p>
          <p>
            Elektronska pošta: <a href="mailto:info@enetra.si">info@enetra.si</a>
          </p>
          <p>Davčna številka: 21280410</p>
          <p>Matična številka: 8133930000</p>
          <p>EIC koda: 28X000000000047Q</p>
          <p>ACER koda: A0015310W.SI</p>
        </Details>
      </Wrapper>
    </>
  );
};
